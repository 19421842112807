//common styles
:root {
    --clr-primary: #f26123;
    --clr-green: #96c22b;
    --clr-text: #58595b;
    scroll-behavior: smooth;
}
body {
    scroll-behavior: smooth;
}
.text-orange {
    color: #f26123!important;
}
.text-center {
    text-align: center;
}
.bg-grey {
    background-color: rgb(247, 247, 247)!important;
}
.bg-white {
    background: #fff;
}
.landing-button--grey {
    background: #ccc!important;
    color: var(--clr-text)!important;
    &:hover {
        color: #e2e2e2!important;
    }
}
ul.sk-list {
	padding-left: 15px;
}

ul.sk-list li {
    list-style: none;
    padding: 0.15em;
}

ul.sk-list li:before {
  content: "\2022";
  color: #F26123;
  font-weight: bold; 
  display: inline-block; 
  width: 1em; 
  margin-left: -1em; 
}

//subproduct pages styles
.sp-page {
    h3 {
        font-size: 2.6rem!important;
        color: #000000;
        font-weight: bold;
        margin-bottom: 1.4em;
        @include respond-to(767) {
            font-size: 2.2rem!important;
        }
    }
    h4 {
        font-size: 2.2rem!important;
        line-height: 1.2;
        margin-bottom: 1em;
        @include respond-to(700) {
            font-size: 2rem!important;
        }
        @include respond-to(420) {
            font-size: 1.8rem!important;
        }
    }
    --section-padding: 72px;
    --sp-title-fs: 70px;
    --sp-title-color: #fff;
    color: #58595b;

    @include respond-to(900) {
        --section-padding: 62px;
    }
    @include respond-to(767) {
        --section-padding: 52px;
    }
    @include respond-to(420) {
        --section-padding: 32px;
    }

    .content__inner {
        @include respond-to(900) {
            padding: var(--section-padding) calc(var(--section-padding)/2);
        }
    }

    .new-kasko.header-block .content__inner h1 {
        @include respond-to(767) {
            font-size: 42px!important;
        }
        @include respond-to(420) {
            font-size: 32px!important;
        }
    }

    @include respond-to(767) {
        .corp-hero__content p.header {
            margin-bottom: 1em;
        }
    }
}
.sp-p {
    font-size: 2rem;
    line-height: 1.4;

    @include respond-to(700) {
        font-size: 1.8rem;
    }
    @include respond-to(420) {
        font-size: 1.6rem;
    }
    @include respond-to(340) {
        font-size: 1.4rem;
    }
}
.sp-sign {
    font-size: 1.4rem;
}

.sp-hero {
    display: flex;
    align-items: flex-end;
    padding: 40px var(--section-padding) var(--section-padding);
    @include respond-to(600) {
        padding: 0;
    }
    &__content {
        @include respond-to(767) {
            width: 100%;
        }
    }
    &__title {
        @include respond-to(900) {
            padding-top: 40px!important;
        }
    }
    &__subtitle {
        display: block!important;

        @include respond-to(400) {
            font-size: 18px!important;
        }
    }
    &__btn {

    }
    &__img {
        margin-bottom: -32px;
        @include respond-to(767) {
            display: none;
        }
        img {
            width: 100%;
        }
    }
}

.sp-hero--orig {
    background: rgb(247, 247, 247);

    .sp-hero {
        &::before {
            display: none;

        }
        // .product__hero }
        &__content {
            @media screen and (min-width: 768px) {
                width: 50%;
            }
            @include respond-to(600) {
                box-sizing: border-box;
            }
            flex-shrink: 0;

            &__inner {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        &__title {
            color: var(--clr-primary);
            text-align: center;
            font-size: 50px!important;
            text-align: center;
        }
        &__subtitle {
            color: var(--clr-text) !important;
            text-align: center;
            font-size: 26px!important;
            @include respond-to(767) {
                font-size: 22px!important;
            }
            @include respond-to(400) {
                font-size: 18px!important;
            }
        }
        &__btn {

        }
    }
}
.sp-content--bg-figure {
    position: relative;
    overflow: hidden;
    --bg-side-length: 440px;

    .content__inner {
        position: relative;
    }

    .bg-figure-item {
        display: block;
        width: var(--bg-side-length);
        height: var(--bg-side-length);
        background: url('/static/img/bg-figure.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
    }
}
.sp-second {
    text-align: center;

    .sp-p {
        margin-bottom: var(--section-padding);
    }
    h4 {
        margin-left: auto;
        margin-right: auto;
        max-width: 520px;
        display: block;

        @include respond-to(420) {
            margin-bottom: 0;
        }
    }

    .bg-figure-item {
        bottom: -220px;
        right: -220px;
    }
}

.sp-what {
    min-height: 420px;
    box-sizing: border-box;
    padding: var(--section-padding) 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;


    @include respond-to(900) {
        min-height: 400px;
    }
    @include respond-to(767) {
        min-height: 220px;
        padding: var(--section-padding) 20px;
    }

    &__content {
        background: rgb(238, 238, 242);
        max-width: 520px;
        width: 40%;
        padding: var(--section-padding) calc(var(--section-padding)/2);
        border-radius: 0 10px 10px 0;
        box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);

        @include respond-to(767) {
            margin: auto;
            width: 90%;
            background: rgba(238, 238, 242, .9);
            border-radius: 10px;
        }
    }
}

.sp-list {
    &__item {
        display: flex;
        align-items: center;

        img {
            margin-right: 32px;
            width: 59px;
            height: 59px;

            @include respond-to(767) {
                width: 42px;
                height: 42px;
                margin-right: 24px;
            }
            @include respond-to(400) {
                width: 36px;
                height: 36px;
                margin-right: 18px;
            }
        }
        + .sp-list__item {
            margin-top: 32px;
        }
    }
}

.sp-page--clear {
    .sp-events .sp-list__item img {
        margin-right: 22px;
        width: 32px;
        height: 32px;    
    }
    .sp-events .sp-list__item+.sp-list__item {
        margin-top: 12px;
    }
}




.sp-list--grid-3 {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	--grip-gap-half: 10px;
    margin: 32px calc(var(--grip-gap-half) * -1);

    .sp-list__item {
        width: 33.33%;
        padding: 0 var(--grip-gap-half) 24px;
        box-sizing: border-box;
    }

    .sp-list__item+.sp-list__item {
        margin-top: 0;
    }
}

.sp-list--img-middle .sp-list__item img {
	width: 45px;
	height: 45px;
	margin-right: 12px;
}

@media screen and (max-width: 900px) {
	.sp-list--grid-3 .sp-list__item {
		width: 50%;
	}
}
@media screen and (max-width: 500px) {
	.sp-list--grid-3 .sp-list__item {
		width: 100%;
		padding: 0 var(--grip-gap-half) 12px;
	}
	.sp-list--img-middle .sp-list__item img {
		width: 36px;
		height: 36px;
		margin-right: 8px;
	}
	.sp-list--grid-3 {
		--grip-gap-half: 0;
		margin: 28px 0 0;
	}
}
.sp-page .accordion__item {
	background: #fff;
}

@media screen and (min-width: 900px) {
	.accordion--with-columns {
		display: flex; 
	}
	.accordion-column {
		width: 50%;
		padding: 0 10px;
	}
}

.sp-events {
    &__list {
        max-width: 920px;
        margin-left: auto;
        margin-right: auto;
    }
    .sp-list__item {
        @include respond-to(600) {
            // align-items: flex-start;
        }
    }
    &__item {

    }

}
.sp-auto {
    display: flex!important;
    position: relative;
    h3 {
        @media screen and (min-width: 900px) {
            text-align: center;
        }
    }

    &__img {
        width: 40%;
        position: relative;
        overflow: hidden;
        display: flex;
        padding-top: 32px;
        align-items: center;
        img {
            position: relative;
            width: 160%;
            left: -60%;
        }
        @include respond-to(1200) {
            width: 30%;
            margin-top: auto;
            margin-bottom: auto;
        }
        @include respond-to(767) {
            position: absolute;
            bottom: 0;
            padding-bottom: 12px;
        }
    }
    &__content {
        padding: var(--section-padding);
        @include respond-to(767) {
        //    padding-bottom: 100px;
        //    text-align: center;
           position: relative;
        }
    }
    &__list {
        @include respond-to(767) {
            flex-direction: column;
            align-items: center;
            padding-left: 30%;
        }
        .sp-list__item {

            + .sp-list__item {
                margin-top: 12px;
            }

            &__img {
                width: 37px;
                height: 37px;
                margin-right: 20px;
                @include respond-to(420) {
                    width: 28px;
                    height: 28px;
                    margin-right: 12px;
                }
            }
        }
    }
}

.sp-benefits {
    --bg-side-length: 700px;
    .bg-figure-item {
        right: -300px;
        bottom: -300px;
    }
    &__list {
        max-width: 920px;
        margin-left: auto;
        margin-right: auto;
    }

    &__item {

    }
}
.sp-choice {
    --bg-side-length: 480px;
    .content__inner {

    }
    &__list {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-left: 20%;

        @include respond-to(767) {
            padding-left: 0;
        }
    }

    .bg-figure-item {
        left: -200px;
        bottom: -200px;
    }

    h3 + p {
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
    }

    .sp-list__item {
        align-items: center;
        text-align: right;

        + .sp-list__item {
            margin-top: 22px;
        }
    }
    &__item__img {
        width: 150px;
        height: 90px;
        display: flex;
        margin-left: 50px;
        flex-shrink: 0;

        @include respond-to(500) {
            width: 90px;
            height: 70px;
            margin-left: 20px;
        }

        img {
            margin-right: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            flex-shrink: 0;
            // margin: auto;
        }
    }
}

.sp-ordering {
    h3 {
        margin-bottom: 1em;
    }
    &__steps {
        display: flex;
        justify-content: center;
        margin: 45px 0;

        @include respond-to(650) {
            flex-direction: column;
            align-items: flex-start;
            max-width: 280px;
            margin: 22px auto;
        }
    }
    &__step {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 90px;
        position: relative;

        @include respond-to(900) {
            margin: 0 60px;
        }
        @include respond-to(650) {
            flex-direction: row;
            margin: 25px 0
        }


        &:not(:last-child)::before {
            content: "";
            display: block;
            width: 91px;
            height: 20px;
            position: absolute;
            left: calc(100% + 45px);
            top: 32px;
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDkxLjAgMjAuMCIgd2lkdGg9IjkxLjAiIGhlaWdodD0iMjAuMCI+DQoJPHBhdGggZD0iTSAwLDEwIEwgOTEuMCwxMCIgbWFya2VyLXN0YXJ0PSIiIG1hcmtlci1lbmQ9InVybCgjZW5kYXJyb3doZWFkOERDNjNGKSIgc3R5bGU9ImZpbGw6bm9uZTsgc3Ryb2tlLXdpZHRoOjJweDsiIHN0cm9rZT0iIzhEQzYzRiI+PC9wYXRoPg0KPC9zdmc+DQo=);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            @include respond-to(900) {
                left: calc(100% + 15px);
            }
            @include respond-to(650) {
                transform: rotate(90deg);
                left: 5px;
                top: calc(100% + 20px);
                width: 36px;
                height: 10px;
            }
        }

        &:first-child {
            @include respond-to(650) {
                margin-top: 10px;
            }
        }

        &__numb {
            --width-size: 65px;
            border: 1px solid var(--clr-primary);
            border-radius: 50%;
            width: var(--width-size);
            height: var(--width-size);
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--clr-primary);
            font-size: 42px;
            margin-bottom: 10px;

            @include respond-to(650) {
                flex-direction: row;
                margin-right: 12px;
                --width-size: 40px;
                font-size: 28px;
                flex-shrink: 0;
                margin-bottom: 0;
            }
        }
        &__title {
            font-size: 1.6rem;
            max-width: 165px;
            text-align: center;
            @include respond-to(650) {
                text-align: left;
                max-width: 220px;
            }
            @include respond-to(400) {
                font-size: 1.4rem;
            }
        }
    }
    &__block {
        margin: 0 auto;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        max-width: 650px;
        padding: calc(var(--section-padding) / 2) var(--section-padding);
        border-radius: 10px;
        box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        position: relative;
        
        @include respond-to(500) {
            padding: 20px 12px;
        }

        &__head {
            @include respond-to(600) {
                padding: 0 25px;
            }
            .sp-p {
                @include respond-to(400) {
                    font-size: 1.6rem;
                }
            }
        }

        &__view {
            display: flex;
            flex-direction: column;
            align-items: center;

            &--first {
                .action-button {
                    margin: 10px auto 12px;
                }
            }
            .form__action {
                .btn + .btn {
                    margin-left: 15px;
                }
            }
            .landing-button--grey {
                letter-spacing: -.5px;
            }
        }

        &.data-preloader {
            &::before {
                z-index: 20;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                content: "";
                display: block;
                background: rgba(255, 255, 255, 0.5);
                backdrop-filter: blur(1.5px);
            }
            &::after {
                z-index: 22;
            }
        }

        .select-label,
        .input-field__input {
            background: transparent!important;
        }
        .premium-sum-input input {
            pointer-events: none;
        }
        .sp-form_request {
            margin-top: 28px;
            width: 100%;
        }
        &__back {
            position: absolute;
            left: 24px;
            top: 24px;
            width: 32px;
            height: 24px;
            cursor: pointer; 
            color: var(--clr-text);
            opacity: .66;
            transition: .2s;
            :hover {
                opacity: 1;
            }
            @include respond-to(600) {
                left: 6px;
                top: 14px;
                width: 28px;
            }
        }
    }
    &__table {
        margin: 3.2rem auto 1rem;
        display: flex;
        align-items: center;
        @include respond-to(600) {
            width: 100%;
            margin: 2rem auto 1rem;
        }
        &__titles {
            margin-right: 40px;

            @include respond-to(600) {
                display: none;
            }
        }
    }
    &__vars {

        input[type='radio']:nth-child(1):checked ~ .sp-ordering__vars__list .sp-ordering__var:nth-child(1),
        input[type='radio']:nth-child(2):checked ~ .sp-ordering__vars__list .sp-ordering__var:nth-child(2),
        input[type='radio']:nth-child(3):checked ~ .sp-ordering__vars__list .sp-ordering__var:nth-child(3),
        input[type='radio']:nth-child(4):checked ~ .sp-ordering__vars__list .sp-ordering__var:nth-child(4),
        input[type='radio']:nth-child(5):checked ~ .sp-ordering__vars__list .sp-ordering__var:nth-child(5), 
        input[type='radio']:nth-child(6):checked ~ .sp-ordering__vars__list .sp-ordering__var:nth-child(6){
            opacity: 1;
            border-color: var(--clr-primary);
            background: #FFEAE0;
            transform: scale(1);
        }
        &__list {
            display: flex;
            flex-wrap: wrap;
            display: flex;
            max-width: 472px;

            @include respond-to(600) {
                justify-content: center;
            }
        }
        @include respond-to(600) {
            width: 100%;
        }
    }
    &__var {
        padding: 20px;
        box-sizing: border-box;
        width: 140px;
        opacity: 0.7;
        border: 1px solid #989898;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0.8px;
        margin: 1rem;
        transition: .2s;
        cursor: pointer;
        transform: scale(.95);
        background: #fff;

        @include respond-to(600) {
            width: 50%;
            max-width: 200px;
            padding: 12px;
            margin: 0 0.6rem;
        }

        .sign {
            color: var(--clr-text);
            font-size: 12px;
            line-height: 1.2;
            margin-bottom: 5px;
            font-weight: normal;
            @media screen and (min-width: 601px) {
                display: none;
            }
        }

        &:hover {
            transform: scale(1);
        }

        &__summ {
            color: var(--clr-green);
            font-size: 2.2rem;
            @include respond-to(400) {
                font-size: 1.8rem;
            }
        }
        &__price {
            margin-top: 16px;
            color: var(--clr-primary);
            font-size: 2.6rem;
            font-weight: bold;
            @include respond-to(400) {
                font-size: 2.2rem;
            }
        }
        &__summ,
        &__price {
            @include respond-to(600) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
    .sp-sign {
        margin-top: 0.8em;
    }
    &__check {
        width: 520px;
        margin: auto;
        max-width: 100%;
        margin: 28px auto 0px;
        
        &__field {
            display: flex;
            white-space: nowrap;

            &__value {
                white-space: normal;
            }
            &__name {
                padding-right: 20px;
                width: 50%;
                flex-shrink: 0;
                @include respond-to(600) {
                    width: auto;
                    &::after{
                        content: ':';
                    }

                }
            }
        }

        ~ .form__action {
            @include respond-to(450) {
                flex-direction: column;
                align-items: center;
                display: flex;
            }
        }
    }
}

.sp-quest {
    overflow: hidden;
    position: relative;
    .bg-figure-item {
        --bg-side-length: 465px;
        left: 15%;
        top: -170px;
    }
}

input[type=checkbox].checkbox--error+label:before {
    border-color: #f32121;
}

input[type=checkbox] ~ .input-field__error {
    color: #f32121;
    font-size: 1.4rem;
    line-height: 1.14em;
    margin: 8px auto 4px;
}

.sp-form_request {
    .form__action {
        display: flex;
        justify-content: center;
    }
    input[type=checkbox]+label {
        margin-top: 0.8em;
    }
    input[type=checkbox]+label {
        font-size: 1.2rem;
    }
}
.sp-form {
    &__field {
        padding-bottom: 24px;
        max-width: 100%;

        &--chip {
            display: flex;
            align-items: center;
            padding-top: 14px;
            padding-bottom: 21px;
            justify-content: space-between;
            padding-right: 10px;
            
            @include respond-to(600) {
                padding-top: 14px;
                padding-bottom: 12px;
            }
            @include respond-to(400) {
                flex-direction: column;
                align-items: flex-start;
            }
            .sp-form__field__label {
                margin-right: 18px;
                @include respond-to(600) {
                    margin-right: 10px;
                }
            }
            .w-chips__chip {
                text-align: center;
                min-width: 45px;
            }
            .w-chips__error {
                font-size: 14px;
                position: absolute;
                left: 0;
            }
        }

        &--checkbox {
            padding-top: 4px;
            padding-bottom: 8px;
            label {
                font-size: initial!important;
            }
        }
    }
    
    .form__group--fields {
        width: 100%;
    }
    .form__group + .form__group {
        padding-top: 16px;
    }
}
.sp-form_construct {
    &.sp-form--columns-2 {
        @media screen and (min-width: 900px) {
            .sp-form_construct__fields {
                display: flex;
                flex-wrap: wrap;
                margin-left: -16px;
                margin-right: -16px;
    
                .input-field {
                    width: calc(50% - 32px);
                    margin-left: 16px;
                    margin-right: 16px;
                    box-sizing: border-box;
                }
            }   
            .sp-form__field--chip {
                padding-right: 0;
            }
        }
    }
    .form__group:first-child {
        padding: 20px 0;
    }
    .sp-form__checkbox--agreement {
        margin-top: 16px;
    }
}


.sp-page {
    .accordion__header {
        position: relative;
    }
    .accordion__header::after {
        position: absolute;
        content: "";
        display: block;
        left: 54px;
        bottom: 1px;
        width: 0;
        height: 1px;
        transition: .3s ease-in-out;
        background-color: #f36c21;
    }
    .accordion__item_show .accordion__header::after {
        width: 68px;
        transition: .6s ease-in-out;
    }
}
